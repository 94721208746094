.light-theme {
  --back-color: #eeefff;
}

.dark-theme {
  --accent-color: "#5DFF4F";
  --back-color: #171717;
}

#root {
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* width: calc(100vw - 20%); */
  width: 100%;
}

:root {
  /*-----------*/
  /* Colors */
  --clr-dark: #171717;
  --clr-white: #ffffff;
  /* Colors */

  /* font-sizes */
  --fs-900: 9.375rem;
  --fs-800: 6.25rem;
  --fs-700: 3.5rem;
  --fs-600: 2rem;
  --fs-500: 1.75rem;
  --fs-400: 1.125rem;
  --fs-300: 1rem;
  --fs-200: 0.875rem;
  /* font-sizes */

  /* font-families */
  --ff-cursive: "Niconne", cursive;
  --ff-inter: "Inter", sans-serif;
  --ff-sans-normal: "Quicksand", sans-serif;
  /* font-families */

  /* Main Section Padding */
  --main-padding: 0 var(--font-rythm);
  /* Padding */

  --flow-gap: 1rem;
  --highlight-background: linear-gradient(
    102deg,
    #0cf 0%,
    rgb(0, 153, 255) 100%
  );
  --divider-var: 0.01rem solid #9aa0a6;
  --background-color: var(--back-color);
  --font-rythm: 1.5rem;
  --line-height: 1.5;
  --system-family-default: "Roboto", sans-serif;
  text-align: justify;
  /* font-size: 62.5%; */
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  font-family: var(--ff-sans-normal);
  line-height: var(--line-height);
  letter-spacing: 0.02rem;
  font-size: var(--fs-400);
  color: var(--clr-dark);
  background-color: var(--background-color);
  /* background: linear-gradient(
      179.3deg,
      rgba(247, 237, 237, 0.2) 0.7%,
      rgba(76, 115, 190, 0.189533) 9.1%,
      rgba(217, 69, 255, 0.170937) 33.27%,
      rgba(173, 135, 235, 0.158219) 42.05%,
      rgba(46, 105, 220, 0.088) 90.55%
    ),
    #ffffff; */
  /* background: url("/images/rainbow-vortex.svg") no-repeat; */
  /* background: url("./sun-tornado.svg") no-repeat; */
  background-size: cover;
  font-weight: 400;
  overflow-x: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

*,
::before,
::after {
  box-sizing: border-box;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
}
